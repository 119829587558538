<template>
  <div v-loading="loading" id="auth_helper_page" style="min-height: 400px">
		<div class="container">
			
		</div>
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
	data() {
		return {
			params: {
				mode: '',
				actionCode: '',
				continueUrl: '',
				lang: 'en'
			},
			loading: true
		}
	},


	mounted() {
		console.log(this.params);
		
		this.params.mode = this.$route.query.mode;
		this.params.actionCode = this.$route.query.oobCode;
		this.params.continueUrl = this.$route.query.continueUrl;
		this.params.lang = this.$route.query.lang || 'en';

		if (this.params.mode == 'resetPassword') {
			this.handleResetPassword();
		} else if (this.params.mode == 'recoverEmail') {
			this.handleRecoverEmail();
		} else if (this.params.mode == 'verifyEmail') {
			this.handleVerifyEmail();
		} else if (this.params.mode == 'signIn') {
			this.handlePasswordlessSignIn();
		}
	},


	methods: {
		handleVerifyEmail() {
			firebase.auth().applyActionCode(this.params.actionCode).then(async result => {
				this.loading = false;

				return this.$router.push('/login?action_step=EMAIL_VERIFIED').catch(() => {});
				
			}).catch(_ => {
				this.loading = false;
				this.$message({ type: 'error', showClose: true, message: 'An error has happened during your email verification' });
				return this.$router.push('/');
			});

			// Add catch
		},

		handleRecoverEmail() {

		},

		handleResetPassword() {
			this.$router.push(`/forgot-password?action=FORGOT_PASSWORD_RESET&oobCode=${this.params.actionCode}`);
		},

		handlePasswordlessSignIn() {
			// Check if right
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = localStorage.getItem("emailForSignIn");

        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }
        // The client SDK will parse the code from the link for you.
        firebase.auth()
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // Clear email from storage.
           	localStorage.removeItem("emailForSignIn");
						this.loading = false;
						this.$router.push('/');
          })
          .catch((error) => {
						this.loading = false;
						this.$message({ type: 'error', showClose: true, message: 'There is an error logging in with passwordless email' });
						console.log('error', error);
						this.$router.push('/');
          });
      } else {
				this.loading = false;
				this.$message({ type: 'error', showClose: true, message: 'There is an error logging in with passwordless email' });
				this.$router.push('/');
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>